import React from 'react';
import LogoItem from './LogoItem';
import linkedin_logo from './logos/LI-In-Bug.png';
import pdf_placeholder from './logos/icons8-pdf-100.png';
import { LINKEDIN_URL, RESUME_ENG_URL, RESUME_PROD_URL, SUBSCRIBE_URL } from './config/urls.js';


function ContactPage() {
    return (
        <div className="Main-div">
            <div className="left-section">
                {/* Content for the left two-thirds */}
                <h1>Contact</h1>
                <p>To discuss collaborations, job opportunities, or media inquiries, contact me via LinkedIn.</p>
                <LogoItem href={LINKEDIN_URL} src={linkedin_logo} alt="LinkedIn Logo" />
                <p>For personal and non-professional inquiries (or simply saying hello), don't hesitate to <a href="mailto:hello@davidnestoff.com">email me</a>.</p>
                <p>Just want to stay connected? <a href={SUBSCRIBE_URL} target="_blank" rel="noopener noreferrer">Join my mailing list</a> to receive updates on new music releases, short stories, and other exciting projects.</p>
            </div>
            <div className="right-section">
                {/* Content for the right one-third */}
                <div className="resume-thumbnails">
                    <a href={RESUME_ENG_URL} target="_blank" rel="noopener noreferrer">
                        <div className="thumbnail">
                            <img src={pdf_placeholder} alt="Resume 1"/>
                            <p>Engineering Resume</p>
                        </div>
                    </a>
                    <a href={RESUME_PROD_URL} target="_blank" rel="noopener noreferrer">
                        <div className="thumbnail">
                            <img src={pdf_placeholder} alt="Resume 2"/>
                            <p>Product Resume</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;