import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import Header from './Header';
import TopNavigation from './TopNavigation';
import LeftNavigation from './LeftNavigation';
import Writing from './Writing';
import Music from './Music';
import AI from './AI';
import Contact from './ContactPage';
import SubscribePage from './SubscribePage';


function App() {
  return (
    <Router>
      <div className="App App-bg">
        <Header />
        <TopNavigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/music" element={<Music />} />
          <Route path="/ai" element={<AI />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/subscribe" element={<SubscribePage />} />
        </Routes>
        <LeftNavigation />
      </div>
    </Router>

  );
}

export default App;
