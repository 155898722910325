import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navigation.css';

function TopNavigation() {
    const location = useLocation();

    return (
        <nav className="top-nav">
            <ul className="nav-list">
                {location.pathname !== '/' && (
                    <li className="nav-item nav-item-left">
                        <Link to="/" className="back-link">
                            <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="back-icon">
                                <circle cx="12" cy="12" r="12" fill="white"/>
                                <path d="M15 12H9M9 12L12 9M9 12L12 15" stroke="#606060" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Link>
                    </li>
                )}
                <li className="nav-item">
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </nav>
    );
}

export default TopNavigation;
